@import 'fonts';
@import 'components/table';
@import 'components/pagination';

html * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

body {
  -webkit-animation-delay: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes fontfix {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

*:focus {
  outline: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  border-bottom: 0;
}
